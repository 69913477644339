import { useGtm, createGtm } from '@gtm-support/vue-gtm'
import { useStore } from 'vuex'
import { getClickProps, getPvProps } from '~/utils/gtm'
import { ClickElement } from '~/utils/gtm/type'

const completedMixpanelXHR = () => {
  const beforeOnClickAt = window['dataLayer.beforeOnClickAt']
  const onClickLatestAt = window['dataLayer.onClickLatestAt']

  return onClickLatestAt - beforeOnClickAt > 0
}

const waitForMixpanelXHR = (timeout: number) => {
  let count = 0
  return new Promise((resolve) => {
    const intervalId = window.setInterval(() => {
      count++
      if (count > timeout / 1000 || completedMixpanelXHR()) {
        clearInterval(intervalId)
        resolve(true)
      }
    }, 1000)
  })
}

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute()
  const runtimeConfig = useRuntimeConfig()

  // Returns when the GTM_ID is not set,
  // because it is checked with a regular expression in createGtm
  // https://github.com/gtm-support/vue-gtm/blob/2.0.0/src/index.ts#L77-L78
  // https://github.com/gtm-support/core/blob/2.0.0/src/assert-is-gtm-id.ts
  if (!runtimeConfig.public.GTM_ID) {
    return {
      provide: {
        // eslint-disable-next-line no-console
        gtmClickPush: () => console.debug('GTM_ID is not set.'),
        // eslint-disable-next-line no-console
        gtmClickPushAsync: () => console.debug('GTM_ID is not set.'),
        // eslint-disable-next-line no-console
        gtmPvPush: () => console.debug('GTM_ID is not set.'),
      },
    }
  }

  nuxtApp.vueApp.use(
    createGtm({
      id: `${runtimeConfig.public.GTM_ID}`, // 設定項目についてはhttps://www.npmjs.com/package/@gtm-support/vue-gtm から
      queryParams: {
        gtm_auth: `${runtimeConfig.public.GTM_AUTH}`,
        gtm_preview: `${runtimeConfig.public.GTM_PREVIEW}`,
        gtm_cookies_win: 'x',
      },
      defer: true,
      compatibility: false,
      nonce: '2726c7f26c',
      enabled: true,
      debug: false,
      loadScript: true,
      trackOnNextTick: false,
    })
  )
  const store = useStore()
  const gtm = useGtm()

  const gtmClickPush = (element: ClickElement, value?: Record<string, any>) => {
    const props = getClickProps(element, store, value)
    if (gtm?.enabled()) {
      window.dataLayer?.push({
        event: 'nuxtClick',
        ...props,
      })
    }
  }

  const gtmClickPushAsync = async (
    element: ClickElement,
    timeout: number,
    value?: Record<string, any>
  ) => {
    window['dataLayer.beforeOnClickAt'] = Date.now()

    const props = getClickProps(element, store, value)
    if (gtm?.enabled()) {
      window.dataLayer?.push({
        event: 'nuxtClick',
        ...props,
      })
    }

    return await waitForMixpanelXHR(timeout)
  }

  const gtmPvPush = () => {
    const props = getPvProps(route, store)
    if (gtm?.enabled()) {
      window.dataLayer?.push({
        event: 'nuxtPageView',
        ...props,
      })
    }
  }

  watch(route, (to, from) => {
    setTimeout(() => {
      const props = getPvProps(to, store)
      if (gtm?.enabled()) {
        window.dataLayer?.push({
          event: 'nuxtPageView',
          ...props,
        })
      }
    }, 250)
  })

  return {
    provide: {
      gtmClickPush,
      gtmClickPushAsync,
      gtmPvPush,
    },
  }
})
