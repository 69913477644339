import revive_payload_client_4sVQNw7RlN from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_y9dIov5OEk from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt3-vuex-module/src/plugin.js";
import plugin_1UohGbtF8v from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import index_eVrIVTfpN2 from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/filter/index.ts";
import index_6Kk2VIU7BK from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/storage/index.ts";
import catalog_mode_nfk7SCoekI from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/catalog-mode.ts";
import embedScript_U2kb91NCGD from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/embedScript.ts";
import error_handler_kEP5FliEXj from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/error-handler.ts";
import extenstions_dwu9IpABDb from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/extenstions.ts";
import gtm_cXsUZobmSF from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/gtm.ts";
import infiniteloading_bXJi9Mqah5 from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/infiniteloading.ts";
import language_1MpgxzxhMS from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/language.ts";
import onboarding_kfzip8l3qe from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/onboarding.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/sentry.client.ts";
import vue_qrcode_reader_goAiHSkw3q from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/vue-qrcode-reader.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  plugin_y9dIov5OEk,
  plugin_1UohGbtF8v,
  index_eVrIVTfpN2,
  index_6Kk2VIU7BK,
  catalog_mode_nfk7SCoekI,
  embedScript_U2kb91NCGD,
  error_handler_kEP5FliEXj,
  extenstions_dwu9IpABDb,
  gtm_cXsUZobmSF,
  infiniteloading_bXJi9Mqah5,
  language_1MpgxzxhMS,
  onboarding_kfzip8l3qe,
  sentry_client_shVUlIjFLk,
  vue_qrcode_reader_goAiHSkw3q,
  vuetify_7h9QAQEssH
]