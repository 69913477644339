<template>
  <div class="confirm">
    <div class="confirm__header">
      <h2 class="confirm__title">ご注文内容の確認</h2>
      <nav class="confirm__tab-nav">
        <button
          class="confirm__tab-nav__button"
          :class="{ active: activeTabName === tab.QR_CODE }"
          @click="onClickTabLabel(tab.QR_CODE)"
        >
          QRコード
        </button>
        <button
          class="confirm__tab-nav__button"
          :class="{ active: activeTabName === tab.PRODUCTS }"
          @click="onClickTabLabel(tab.PRODUCTS)"
        >
          商品一覧
        </button>
      </nav>
    </div>

    <div class="confirm__total">
      <span class="confirm__total__label">合計金額</span>
      <span class="confirm__total__value"
        >{{ $filter.common.displayPrice(cartConfirm.grandTotal) }}
        <span class="confirm__total__tax">(税込)</span></span
      >
    </div>

    <div class="confirm__tab-container">
      <section
        class="confirm__tab-content confirm__qr"
        :class="{ active: activeTabName === tab.QR_CODE }"
      >
        <div class="confirm__qr__code-container mb-4">
          <QRGenerator ref="qr-generator" :code="cartConfirm.urlForQrCode" />
        </div>
        <p class="confirm__qr__guide-text my-3">
          こちらを<CommonCashierPopup
            btn-title="レジカウンター"
          />にご提示いただき、<br />
          お支払い手続きを行ってください。
        </p>
        <span class="confirm__qr__stock-limit mb-4"
          >商品お取り置き時間：30分</span
        >

        <nuxt-link
          class="confirm__button-link mb-3"
          to="/cart"
          @click="$gtmClickPush('qr_mycard_select_again')"
          >商品をもう一度選ぶ</nuxt-link
        >
        <p class="confirm__qr__ship-guide mb-6">
          配送ご希望いただいた方は決済完了後、住所登録のご案内をお送りいたします。
        </p>
        <p class="confirm__qr__trademark">
          ※QRコードは(株)デンソーウェーブの登録商標です。
        </p>
      </section>

      <section
        class="confirm__tab-content confirm__products"
        :class="{ active: activeTabName === tab.PRODUCTS }"
      >
        <div class="confirm__products__scroll-view pt-5">
          <div v-if="productsWithReceiveStore.length" class="mb-10">
            <h3 class="confirm__products__heading">店頭受取商品</h3>
            <template
              v-for="product in productsWithReceiveStore"
              :key="product.id"
            >
              <CartConfirmProduct :product="product" class="mb-5" />
            </template>
          </div>

          <div v-if="productsWithReceiveShip.length" class="mb-10">
            <h3 class="confirm__products__heading">配送受取商品</h3>
            <template
              v-for="product in productsWithReceiveShip"
              :key="product.id"
            >
              <CartConfirmProduct :product="product" class="mb-5" />
            </template>
          </div>

          <div class="confirm__products__total">
            <div class="confirm__products__total__container">
              <span class="confirm__products__total__label"> 小計: </span>
              <span class="confirm__products__total__value">{{
                $filter.common.displayPrice(cartConfirm.subtotal)
              }}</span>
            </div>
            <div
              v-if="cartConfirm.shipping"
              class="confirm__products__total__container"
            >
              <span class="confirm__products__total__label"> 配送料: </span>
              <span class="confirm__products__total__value">
                {{
                  $filter.common.displayPrice(cartConfirm.shipping.charge)
                }}</span
              >
            </div>
            <div
              v-if="cartConfirm.wrapping && cartConfirm.wrapping.enable"
              class="confirm__products__total__container"
            >
              <span class="confirm__products__total__label"> ギフト: </span>
              <span class="confirm__products__total__value">
                {{
                  $filter.common.displayPrice(cartConfirm.wrapping.fee)
                }}</span
              >
            </div>
          </div>
          <nuxt-link
            class="confirm__button-link mb-5"
            to="/cart"
            @click="$gtmClickPush('qr_mycard_select_again')"
            >商品をもう一度選ぶ</nuxt-link
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { CartConfirm, CartProduct, RECEIVE } from '~/models/Cart'

const TAB = {
  QR_CODE: 'qrCode',
  PRODUCTS: 'products',
} as const
type Tab = (typeof TAB)[keyof typeof TAB]

type DataType = {
  cartConfirm: CartConfirm
  activeTabName: Tab
  pollingHandleNumber?: number
  draftOrderAlias: string
}

type AsyncDataType = Pick<DataType, 'cartConfirm'>

const getTabElementProps = (tabName: Tab) => {
  switch (tabName) {
    case 'qrCode':
      return 'qr_mycard_qr_code'
    case 'products':
      return 'qr_mycard_product_list'
  }
}

const cartRepository = useCartRepository()
definePageMeta({
  layout: 'empty',
})

export default defineNuxtComponent({
  async asyncData(): Promise<AsyncDataType | void> {
    const route = useRoute()
    const draftOrderAlias = Array.isArray(route.params.draftOrderAlias)
      ? route.params.draftOrderAlias[0]
      : route.params.draftOrderAlias

    const cartConfirm = await cartRepository.getCartConfirm(draftOrderAlias)

    if ('message' in cartConfirm) {
      createError({ statusCode: 404 })
      return
    }

    if ('errorCode' in cartConfirm) {
      if ('redirectDraftOrderAlias' in cartConfirm) {
        navigateTo(`/qr/${cartConfirm.redirectDraftOrderAlias}`)
        return
      }
      if ('orderAlias' in cartConfirm) {
        navigateTo(`/order/${cartConfirm.orderAlias}`)
        return
      }
      navigateTo(`/qr/timeout`)
      return
    }

    return { cartConfirm }
  },
  data(): DataType {
    const route = useRoute()
    return {
      cartConfirm: {} as CartConfirm,
      activeTabName: TAB.QR_CODE,
      draftOrderAlias: Array.isArray(route.params.draftOrderAlias)
        ? route.params.draftOrderAlias[0]
        : route.params.draftOrderAlias,
    }
  },
  computed: {
    productsWithReceiveStore(): CartProduct[] {
      return (
        this.cartConfirm.products?.filter((it: CartProduct) => {
          return it.receive === RECEIVE.STORE
        }) ?? []
      )
    },
    productsWithReceiveShip(): CartProduct[] {
      return (
        this.cartConfirm.products?.filter((it: CartProduct) => {
          return it.receive === RECEIVE.SHIP
        }) ?? []
      )
    },
    tab() {
      return TAB
    },
  },
  mounted() {
    this.startFetchStatusPolling()
  },
  beforeUnmount() {
    this.clearFetchStatusPolling()
  },
  methods: {
    gotoCart() {
      this.$router.replace({ path: '/cart' })
    },
    onClickTabLabel(tabName: Tab) {
      this.activeTabName = tabName
      this.$gtmClickPush(getTabElementProps(tabName))
    },
    startFetchStatusPolling() {
      this.clearFetchStatusPolling()
      this.pollingHandleNumber = window.setInterval(() => {
        cartRepository
          .getDraftOrderStatus(this.draftOrderAlias)
          .then((res) => {
            switch (res.status) {
              case 'ordered': {
                this.clearFetchStatusPolling()
                if (!res.orderAlias) {
                  // TODO ユーザーにサーバーエラー通知をする
                  break
                }

                this.$nuxt.$router.replace({
                  path: `/order/${res.orderAlias}`,
                })
                break
              }
              case 'cancel': {
                this.clearFetchStatusPolling()
                window.location.reload()
                break
              }
              case 'active':
                break
            }
          })
          .catch(() => {
            this.clearFetchStatusPolling()
            // TODO ユーザーにサーバーエラー通知をする
          })
      }, 3000)
    },
    clearFetchStatusPolling() {
      if (this.pollingHandleNumber) {
        clearInterval(this.pollingHandleNumber)
        this.pollingHandleNumber = undefined
      }
    },
  },
})
</script>

<style lang="sass" scoped>
.confirm__header
  background-color: $white-bg-color
  padding-bottom: 3px

.confirm__title
  font-weight: 600
  font-size: 16px
  display: block
  text-align: center
  padding: 30px 0 16px 0

.confirm__tab-nav
  width: 85%
  display: flex
  justify-content: space-between
  margin: 0 auto

.confirm__tab-container
  position: relative

.confirm__tab-nav__button
  font-size: 12px
  font-weight: 600
  width: calc(100% / 2)
  padding: 10px 0
  border-bottom: solid 3px $white-bg-color
  color: $sub-color
  &:focus
    outline: none
  &.active
    border-bottom: solid 3px $main-color

.confirm__tab-content
  opacity: 0
  position: absolute
  top: 0
  display: none
  width: 100%
  &.active
    animation: fadeIn 0.6s
    opacity: 1
    display: block
    background-color: $main-bg-color

.confirm__total
  display: flex
  flex-direction: column
  align-items: center
  padding: 20px 0 10px 0

.confirm__total__label
  font-size: 14px
  font-weight: bold

.confirm__total__value
  font-family: $en-font-family
  font-size: 26px

.confirm__total__tax
  font-weight: bold
  font-size: 11px

.confirm__qr__guide-text
  font-size: 14px
  text-align: center

.confirm__qr__stock-limit
  font-size: 11px
  color: $sub-color
  display: block
  margin: 0 auto
  text-align: center

.confirm__button-link
  color: $sub-color
  font-weight: 600
  background: #E7E8E9
  box-shadow: inset -6px -3px 6px #FFFFFF, inset 4px 2px 6px rgba(72, 100, 125, 0.3)
  border-radius: 10px
  text-decoration: none
  opacity: 0.6
  width: 264px
  height: 54px
  display: flex
  align-items: center
  justify-content: center
  margin: 0 auto

.confirm__qr__ship-guide
  font-size: 11px
  color: #555
  width: 264px
  display: block
  margin: 0 auto
  line-height: 16px

.confirm__qr__trademark
  font-size: 11px
  color: $sub-color
  text-align: center
  display: block

.confirm__qr__code-container
  border-radius: 30px
  width: 168px
  height: 168px
  overflow: hidden
  box-shadow: 4px 4px 14px #CCCCCC, -3px -3px 10px #FFFFFF
  display: flex
  align-items: center
  justify-content: center
  margin: 0 auto

.confirm__products__scroll-view
  width: 90%
  height: 65vh
  overflow-y: scroll
  -webkit-overflow-scrolling: touch
  margin: 0 auto
  scrollbar-width: thin

.confirm__products__heading
  color: $sub-color
  position: relative
  font-size: 14px
  margin-bottom: 10px
  &:after
    position: absolute
    content: ' '
    width: calc(100% - 7em)
    height: 1px
    background: $sub-color
    top: 0
    right: 4px
    bottom: 0
    margin: auto

.confirm__products__total
  border-top: solid 1px $main-color
  display: flex
  flex-direction: column
  align-items: center
  margin: 20px 0
  padding-top: 20px

.confirm__products__total__container
  display: flex
  justify-content: space-between
  width: 40%
  margin: 0 auto

.confirm__products__total__label
  font-size: 14px
  font-weight: bold
  color: $sub-color

.confirm__products__total__value
  font-family: $en-font-family

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1
</style>
