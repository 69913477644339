/* eslint-disable no-extend-native */
import numeral from 'numeral'
import { defineNuxtPlugin } from '#app'

declare global {
  interface Number {
    separatePrice: () => string
  }
}

Number.prototype.separatePrice = function (): string {
  return numeral(this).format('0,0')
}

export default defineNuxtPlugin((nuxtApp) => {})
