import { Product, Variant, VariantOption } from '~/models/Product'
import { genThumbImageUrl, genMainImageUrl } from '~/shared/image_url'

type DisplayOption = {
  name: string
  displayName: string
  options: { label: string; displayLabel: string; disabled: boolean }[]
}
export class ProductFilter {
  mainImageUrl(src?: string): string {
    return src
      ? genMainImageUrl(src)
      : 'https://prod-common-image.s3.ap-northeast-1.amazonaws.com/public/catalog/cbs-logo.jpg'
  }

  thumbImageUrl(src: string): string {
    return src
      ? genThumbImageUrl(src)
      : 'https://prod-common-image.s3.ap-northeast-1.amazonaws.com/public/catalog/cbs-logo.jpg'
  }

  getDisplayPrice(
    selectVariant: Variant | undefined,
    product: Product
  ): string {
    if (!selectVariant) {
      return `¥${product.variants[0].price.separatePrice()}`
    }
    return `¥${selectVariant.price.separatePrice()}`
  }

  getUniqueOptions(options: VariantOption[]): VariantOption[] {
    const seen = new Set<string>()

    return options.filter((option) => {
      const key = `${option.name}|${option.value}`
      if (seen.has(key)) {
        return false
      } else {
        seen.add(key)
        return true
      }
    })
  }

  getDisplayOptions(product: Product): DisplayOption[] {
    if (!product) {
      return []
    }

    const variants = product.variants
    const options = variants.flatMap((variant) => {
      return variant.options ?? []
    })
    const tempOptions: Record<VariantOption['name'], VariantOption[]> = {}

    options.forEach((option) => {
      if (tempOptions[option.name]) {
        tempOptions[option.name].push(option)
      } else {
        tempOptions[option.name] = [option]
      }
    })

    return Object.entries(tempOptions).map(([key, options]) => ({
      name: key,
      displayName: options[0].displayName ?? key,
      options: [...this.getUniqueOptions(options)].map((option) => {
        const disabled = variants
          .filter((variant) => {
            return variant.options?.some(
              (o) => o.name === key && o.value === option.value
            )
          })
          .every((variant) => {
            return variant.totalStock === 0
          })

        return {
          label: option.value,
          displayLabel: option.displayValue ?? option.value,
          disabled,
        }
      }),
    }))
  }
}
