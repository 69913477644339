import { FetchError } from 'ofetch'
import { FilterOption } from '~/models/FilterOption'
import { Product, ShopifyProductId } from '~/models/Product'
import { customFetch } from './repository/customFetch'
import { FilterOptionResponse } from './repository/product/filter.res'
import {
  ProductResponse,
  ProductsResponse,
  toProductDetail,
} from './repository/product/product.res'

class ProductRepository {
  readonly customFetch = customFetch

  public async getProductById(
    shopifyProductId: ShopifyProductId,
    lang: string | null = null
  ): Promise<{ product: Product; error: FetchError | null }> {
    const { data, error } = await this.customFetch<ProductResponse>(
      `/catalog/products/${shopifyProductId}`,
      {
        params: {
          ...(lang ? { lang } : {}),
        },
      }
    )

    if (error.value) {
      throw error.value
    }

    if (!data.value) {
      throw new Error('product not found.')
    }

    return {
      product: toProductDetail(data.value),
      error: error.value,
    }
  }

  public async getProducts(
    areaId: number,
    page: number,
    size: number,
    tenantIds: number[]
  ): Promise<{ products: Product[]; count: number }> {
    const { data, error } = await this.customFetch<ProductsResponse>(
      '/catalog/products',
      {
        params: {
          area_id: areaId,
          page,
          size,
          tenant_ids: tenantIds,
        },
      }
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('products not found.')
    }

    return {
      products: data.value.products.map((it) => toProductDetail(it)),
      count: data.value.count,
    }
  }

  public async getFilterOption(areaId: number): Promise<FilterOption> {
    const { data, error } = await this.customFetch<FilterOptionResponse>(
      '/catalog/products-filters',
      {
        params: {
          area_id: areaId,
        },
      }
    )

    if (error.value) {
      throw error.value
    }
    if (!data.value) {
      throw new Error('filter option not found.')
    }

    return {
      tenants: data.value.map((it) => ({
        ...it,
        logoSrc: it.logoSrc ? new URL(it.logoSrc) : undefined,
      })),
    }
  }
}

export function useProductRepository() {
  return new ProductRepository()
}
