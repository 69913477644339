<template>
  <v-container class="p-cart-complete-container">
    <div class="d-flex flex-column">
      <div class="text-center mt-8">
        <IconSuccess class="icon-success" />
        <h2 class="subtitle-1">ご購入ありがとうございます</h2>
        <p class="p-cart-complete-common-message">
          登録いただいたご連絡先に
          <br />
          ご注文詳細をお送りいたしました。
        </p>
      </div>

      <div v-if="receiveStore" class="text-center">
        <p class="p-cart-complete-recieve-store-message">
          ただいま商品を準備しております。
          <br />
          ご用意でき次第、送付される注文番号を
          <br />
          <CommonCashierPopup
            btn-title="レジカウンター"
          />のスタッフにお伝えください。
        </p>
      </div>

      <hr v-if="receiveShip" />

      <div v-if="receiveShip" class="text-center">
        <p class="p-cart-complete-recieve-ship-icon">
          <IconDeliveryTruck />
        </p>
        <p class="p-cart-complete-recieve-ship-title">
          配送指定の商品がございます
        </p>
        <p class="p-cart-complete-recieve-ship-message">
          登録いただいたご連絡先に
          <br />
          住所ご登録フォームを送付いたしました。
          <br />
          お届け先をご入力ください。
        </p>
        <p class="p-cart-complete-recieve-ship-expiration-date">
          有効期限：１週間（{{ effectiveDate }}）まで
        </p>
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import IconDeliveryTruck from '@/assets/images/icon/icon-delivery-truck.svg?component'
import IconSuccess from '@/assets/images/icon/icon-success.svg?component'
import { OrderInfo } from '~/models/Order'
import { dayjs } from '~/shared/date'

type DataType = OrderInfo
type AsyncDataType = DataType

const orderRepository = useOrderRepository()
definePageMeta({
  layout: 'empty',
})

export default defineNuxtComponent({
  components: {
    IconDeliveryTruck,
    IconSuccess,
  },
  async asyncData(): Promise<AsyncDataType | void> {
    const route = useRoute()
    const orderAlias = Array.isArray(route.params.orderAlias)
      ? route.params.orderAlias[0]
      : route.params.orderAlias
    if (!orderAlias) {
      createError({ statusCode: 404, fatal: true })
      return
    }

    const { data: orderInfo, error } = await orderRepository.getOrder(
      orderAlias
    )

    if (error.value) {
      createError({ statusCode: 404, fatal: true })
      return
    }

    return orderInfo
  },
  data(): DataType {
    return {
      receiveStore: false,
      receiveShip: false,
    }
  },

  computed: {
    effectiveDate() {
      return dayjs().add(7, 'day').format('YYYY年MM月DD日')
    },
  },
  beforeUnmount() {
    this.$store.commit('cart/setCartCountCached', { cached: false })
  },
})
</script>

<style lang="sass" scoped>
.p-cart-complete-container
  padding: 20px 30px
.subtitle-1
  font-size: 16px
  font-weight: bold
  text-align: center
  margin-bottom: 16px
  margin-top: 13px
  line-height: 24px
  letter-spacing: 0
.p-cart-complete-common-message
  margin-top: 12px
  margin-bottom: 0
  font-size: 14px
  line-height: 1.5
.p-cart-complete-recieve-store-message
  margin-top: 33px
  margin-bottom: 0
  font-size: 14px
  line-height: 1.5
.p-cart-complete-recieve-ship-icon
  margin-top: 27px
  margin-bottom: 0
.p-cart-complete-recieve-ship-title
  margin-top: 7px
  margin-bottom: 0
  color: $sub-color
  font-weight: bold
  font-size: 14px
  line-height: 1.5
.p-cart-complete-recieve-ship-message
  margin-top: 19px
  margin-bottom: 0
  font-size: 14px
  line-height: 1.5
.p-cart-complete-recieve-ship-expiration-date
  margin-top: 8px
  color: $sub-color
  font-size: 11px
  line-height: 16px
hr
  margin-top: 39px
  border: 0
  background-color: #AEAEAE
  height: 1px
.icon-success
  $size: 72px
  width: $size
  height: $size
</style>
