<template>
  <div class="theme--white address d-flex flex-column">
    <AddressStepNav
      title="配送先の設定"
      :sub-title="navSubTitle"
      :current-step="navCurrentStep"
      :total-step="3"
    />

    <div v-if="isEditing">
      <div class="mx-8">
        <div class="d-flex flex-column mb-3">
          <div class="d-flex flex-row">
            <v-text-field
              v-model="address.values.lastName"
              density="comfortable"
              variant="outlined"
              placeholder="姓"
              :error="address.hasErrors.lastName"
              hide-details
              class="mr-4"
              @blur="onBlurLastName"
            ></v-text-field>
            <v-text-field
              v-model="address.values.firstName"
              density="comfortable"
              variant="outlined"
              placeholder="名"
              :error="address.hasErrors.firstName"
              hide-details
              @blur="onBlurFirstName"
            ></v-text-field>
          </div>
          <span
            v-show="address.hasErrors.lastName || address.hasErrors.firstName"
            class="address__error-text"
            >使用できない文字が含まれています</span
          >
        </div>

        <div class="d-flex flex-column">
          <div class="d-flex flex-row" style="height: 60px">
            <div class="mr-4" style="width: 60%">
              <v-text-field
                v-model="address.values.postalCode"
                density="comfortable"
                variant="outlined"
                placeholder="郵便番号"
                type="tel"
                :error="address.hasErrors.postalCode"
                hide-details
                @blur="onBlurPostalCode"
              ></v-text-field>
            </div>
            <AddressSelectPrefectures
              :prefecture="address.values.prefecture"
              @update:prefecture="
                (newValue) => (address.values.prefecture = newValue)
              "
            />
          </div>
          <span
            v-show="address.hasErrors.postalCode"
            class="address__error-text"
            >郵便番号は「半角数字」「7~8桁」で入力してください</span
          >
        </div>

        <div class="d-flex flex-column mb-3">
          <v-text-field
            v-model="address.values.address1"
            density="comfortable"
            class="w-full"
            variant="outlined"
            placeholder="住所（町域名・番地）"
            :error="address.hasErrors.address1"
            hide-details
            @blur="onBlurAddress1"
          ></v-text-field>
          <span v-show="address.hasErrors.address1" class="address__error-text"
            >使用できない文字が含まれています</span
          >
        </div>

        <div class="d-flex flex-column mb-3">
          <v-text-field
            v-model="address.values.address2"
            density="comfortable"
            class="w-full"
            variant="outlined"
            placeholder="建物名"
            :error="address.hasErrors.address2"
            hide-details
            @blur="onBlurAddress2"
          ></v-text-field>
          <span v-show="address.hasErrors.address2" class="address__error-text"
            >使用できない文字が含まれています</span
          >
        </div>

        <div class="d-flex flex-column mb-3">
          <v-text-field
            v-model="address.values.email"
            density="comfortable"
            class="w-full"
            variant="outlined"
            placeholder="メールアドレス"
            type="email"
            :error="address.hasErrors.email"
            hide-details
            @blur="onBlurEmail"
          ></v-text-field>
          <span v-show="address.hasErrors.email" class="address__error-text"
            >メールアドレスは「半角英数字」「@」で入力してください</span
          >
        </div>

        <div class="d-flex flex-column mb-3">
          <v-text-field
            v-model="address.values.tel"
            density="comfortable"
            class="w-full"
            variant="outlined"
            placeholder="電話番号"
            :error="address.hasErrors.tel"
            type="tel"
            hide-details
            @blur="onBlurTel"
          ></v-text-field>
          <span v-show="address.hasErrors.tel" class="address__error-text"
            >電話番号は「半角数字」「10~11桁」で入力してください</span
          >
        </div>
      </div>

      <div class="text-center my-8">
        <v-btn
          color="primary"
          :disabled="!address.isFilledRequire || address.hasError"
          class="address__button"
          :loading="requesting"
          @click="goToConfirm"
          >確認画面に進む</v-btn
        >
      </div>
    </div>

    <AddressConfirm
      v-else
      :address="address"
      :shipment-alias="shipmentAlias"
      @go-to-edit="goToEdit"
    />
  </div>
</template>

<script lang="ts">
import { Address } from '~/models/Address'

type DataType = {
  shipmentAlias: string
  address: Address
  isEditing: boolean
  navSubTitle: string
  navCurrentStep: number
  requesting: boolean
}

type CurrentStatusParam = {
  isEditing?: boolean
  subTitle?: string
  currentStep?: number
}

type AsyncDataType = Pick<DataType, 'shipmentAlias'>

const addressRepository = useAddressRepository()

definePageMeta({
  layout: 'empty',
})

export default defineNuxtComponent({
  validate(): boolean {
    return !!useRoute().params.shipmentAlias.length
  },

  async asyncData(): Promise<AsyncDataType | void> {
    const route = useRoute()
    const shipmentAlias = Array.isArray(route.params.shipmentAlias)
      ? route.params.shipmentAlias[0]
      : route.params.shipmentAlias
    const response = await addressRepository
      .getStatus(shipmentAlias.toString())
      .catch((err) => err)

    if ('message' in response) {
      showError(createError({ statusCode: 404, fatal: true }))
      return
    }

    if (response.status === 'expired') {
      // TODO 期限切れページを実装する？
      createError({ statusCode: 500, fatal: true })
    } else if (response.status === 'inputted') {
      navigateTo(`/address/${shipmentAlias}/complete`)
    }

    return {
      shipmentAlias,
    }
  },

  data(): DataType {
    return {
      shipmentAlias: '',
      address: new Address(),
      isEditing: true,
      navSubTitle: '',
      navCurrentStep: 1,
      requesting: false,
    }
  },

  mounted() {
    this.setCurrentStatus()
  },

  methods: {
    onBlurLastName() {
      this.address.validateZenkakuText('lastName')
    },

    onBlurFirstName() {
      this.address.validateZenkakuText('firstName')
    },

    onBlurAddress1() {
      this.address.validateZenkakuText('address1')
    },

    onBlurAddress2() {
      this.address.validateZenkakuText('address2')
    },

    onBlurEmail() {
      this.address.validateEmail()
    },

    onBlurTel() {
      this.address.validateTel()
    },

    async onBlurPostalCode() {
      this.address.formatPostalCode()
      this.address.validatePostalCode()
      if (this.address.hasErrors.postalCode) {
        return
      }

      await this.address.setAddress()
      this.address.validateZenkakuText('address1')
    },

    goToEdit() {
      this.setCurrentStatus()
    },

    async goToConfirm() {
      this.requesting = true

      const isValidated = await addressRepository.validate(
        this.shipmentAlias,
        this.address.values
      )

      if (isValidated) {
        this.setCurrentStatus({
          isEditing: false,
          subTitle: '入力した情報を確認する',
          currentStep: 2,
        })
      } else {
        // TODO
      }

      this.requesting = false
    },

    setCurrentStatus(param?: CurrentStatusParam) {
      this.isEditing = param?.isEditing ?? true
      this.navSubTitle = param?.subTitle ?? '住所を入力する'
      this.navCurrentStep = param?.currentStep ?? 1
    },
  },
})
</script>

<style lang="sass" scoped>
.address__title
  font-size: 14px

.address__sub-title
  font-size: 14px
  font-weight: normal

.address__step-bar
  max-width: 180px
  width: 60%
  margin: 16px auto

.address__select-prefectures
  width: 62%

.address__button
  font-weight: bold
  font-size: 16px
  border-radius: 10px
  width: 272px
  height: 64px !important

.address__error-text
  margin-top: 8px
  margin-left: 8px
  font-size: 11px
  font-weight: bold
  color: $error-color
</style>

<style lang="sass">
// overwrite vuetify default styles
// ------------------------------------------------------------
.address
  .v-input__control
    & .v-field--error
      .v-field__field
        background-color: rgba(252, 221, 221, 0.6) !important
      input
        color: $error-color
    &.w-full
      .v-field__field
        padding: 0 !important
    .v-field__field
      background-color: rgba(239, 239, 239, 0.6) !important
      border-radius: 5px
      box-shadow: inset 1px 1px 4px rgba(153, 153, 153, 0.3)  !important
      min-height: 43px !important
      margin-bottom: 0 !important
      input
        font-size: 16px
        transform: scale(calc(15 / 16))
  .v-field__outline__start, .v-field__outline__end
    border: none

  .v-select
    .v-input__slot
      width: 100%
      height: 43px !important
      background: $white-bg-color !important
      box-shadow: 1px 1px 5px rgba(153, 153, 153, 0.2), -4px -4px 8px #fff !important
  .v-select__selection.v-select__selection--comma
    font-size: 14px
  .v-input__append-inner
    margin: auto !important
  .mdi-menu-down::before
    content: ""
    position: relative
    top: -2px
    width: 8px
    height: 8px
    border-top: 2px solid $sub-color
    border-right: 2px solid $sub-color
    transform: rotate(135deg)
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg
    background: $disabled-color !important
    color: $main-w-color !important
  .v-btn--is-elevated
    box-shadow: none !important
</style>
