import { Store } from 'vuex'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    const store = nuxtApp.$store as Store<any>
    const languageStore = useLanguageStore()

    if (store.getters.isChat) {
      languageStore.syncLocale()
    } else {
      languageStore.setLocale('ja')
    }
  })
})
