<template>
  <div>
    <BrandDetail
      :areas="brand.areas"
      :brand-name="brand.name"
      :description="brand.description"
      :introduction="brand.introduction"
    />

    <div class="brand__products__header">
      <h2 class="brand__products__header-title en">ALL LIST</h2>
      <span v-if="isWebCatalog" class="brand__products__header-sub"
        >全商品</span
      >
    </div>

    <ProductBrandProductList :products="products" :infinite-handler="load" />
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { Area } from '~/models/Area'
import { Brand, BrandProduct } from '~/models/Brand'
import { BonsaiError, BonsaiErrors } from '~/composables/repository/customFetch'

type DataType = {
  brand: Brand
  products: BrandProduct[]
  maxPage: number
  nextPage: number
  isWebCatalog: boolean
}

type BrandProductsResponse = {
  products: BrandProduct[]
  count?: number
}

const PAGING_SIZE = 10
const brandRepository = useBrandRepository()
definePageMeta({
  layout: 'area',
})

export default defineComponent({
  props: {
    description: {
      type: String,
      default: '',
    },
    introduction: {
      type: String,
      default: '',
    },
    areas: {
      type: Array as PropType<Area[]>,
      default: () => [],
    },
  },

  async setup() {
    const store = useStore()
    const isWebCatalog = computed((): boolean => store.getters.isWebCatalog)

    const route = useRoute()
    const languageStore = useLanguageStore()
    const language = computed(() =>
      store.getters.isWebCatalog ? null : languageStore.languageCode
    )
    const brandId = parseInt(
      Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
    )
    const { products, count } = await brandRepository.getBrandWithProducts(
      PAGING_SIZE,
      brandId,
      null,
      language.value
    )

    if (!products) {
      throw createError({ statusCode: 404, fatal: true })
    }

    const { brand } = await brandRepository
      .getBrand(brandId, language.value)
      .catch((e) => {
        const data: BonsaiError | undefined = e?.data
        const statusCode =
          data?.name === BonsaiErrors.DataNotFoundError ? 404 : 500
        throw createError({ statusCode, fatal: true })
      })
    if (!('name' in brand)) {
      throw createError({ statusCode: 404, fatal: true })
    }

    return {
      brand: {
        id: brandId,
        name: brand.name,
        nameKana: brand.nameKana,
        email: brand.email,
        description: brand.description,
        introduction: brand.introduction,
        companyId: brand.companyId,
        logoSrc: brand.logoSrc,
        collectionId: brand.collectionId,
        areas: brand.areas,
      },
      products,
      maxPage: Math.ceil(count / PAGING_SIZE),
      nextPage: 2,
      isWebCatalog,
      language,
    }
  },
  data(): DataType {
    return {
      // eslint-disable-next-line vue/no-dupe-keys
      brand: {
        id: 0,
        name: '',
        nameKana: '',
        email: '',
        description: '',
        introduction: '',
        companyId: 0,
        logoSrc: '',
        collectionId: '',
        areas: [],
      },
      // eslint-disable-next-line vue/no-dupe-keys
      products: [],
      // eslint-disable-next-line vue/no-dupe-keys
      maxPage: 0,
      // eslint-disable-next-line vue/no-dupe-keys
      nextPage: 1,
      // eslint-disable-next-line vue/no-dupe-keys
      isWebCatalog: true,
    }
  },

  methods: {
    async load($state: any) {
      const language = this.isWebCatalog
        ? null
        : this.language ?? DEFAULT_LANGUAGE.code

      if (this.nextPage > this.maxPage) {
        $state.complete()
        return
      }

      const afterProductId = this.products[this.products.length - 1].id
      const { products } = await brandRepository.getBrandWithProducts(
        PAGING_SIZE,
        this.brand.id,
        afterProductId,
        language
      )
      if (products) {
        this.products.push(...products)
      }
      this.nextPage += 1
      $state.loaded()
    },
  },
})
</script>

<style lang="sass" scoped>
.brand__products__header
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 10px

.brand__products__header-title
  font-size: 24px
  line-height: 1.2
  font-weight: bold

.brand__products__header-sub
  font-size: 12px
  line-height: 1.5
</style>
