import { ModeType, Mode } from '~/utils/url'

export const state = () => ({
  mode: Mode.WebCatalog as ModeType,
})

export type RootState = ReturnType<typeof state>

export const mutations = {
  setMode(state: RootState, mode: ModeType) {
    state.mode = mode
  },
}

export const getters = {
  mode: (state: RootState) => state.mode,
  isChat: (state: RootState) => state.mode === 'chat',
  isWebCatalog: (state: RootState) => state.mode === 'web-catalog',
}
