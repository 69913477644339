import validate from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import cart_45count_45global from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/middleware/cart_count.global.ts";
import redirect_45chat_45global from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/middleware/redirect-chat.global.ts";
import manifest_45route_45rule from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cart_45count_45global,
  redirect_45chat_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}