import { RouteLocationNormalizedLoaded } from 'vue-router'
import { Store } from 'vuex'
import {
  ClickElement,
  ElementProps,
  TenantIdProps,
  TenantIdUrlProps,
  ProductIdsReceiveListProps,
  ProductIdOrderProps,
  ProductIdOrderVariantIdProps,
  ProductIdOrderMovieProps,
  ProviderProps,
  ClickProps,
  PageviewProps,
  AreaIdTenantIdProps,
  AreaIdProps,
  AreaIdSearchProps,
  AreaIdFilterResetProps,
  CatalogQRCodeScanSuccess,
} from './type'

// @see GTM [Mixpanel - track - Click]
const createClickProps = (props: Partial<ClickProps>): ClickProps => ({
  element: props.element,
  areaId: props.areaId,
  tenantId: props.tenantId,
  tenantIds: props.tenantIds,
  brandName: props.brandName,
  brandUrl: props.brandUrl,
  position: props.position,
  productId: props.productId,
  productIds: props.productIds,
  receiveList: props.receiveList,
  price: props.price,
  priceList: props.priceList,
  provider: props.provider,
  movieUrl: props.movieUrl,
  variantId: props.variantId,
  active: props.active,
  scanValue: props.scanValue,
})

// @see GTM [Mixpanel - track - Pageview]
const createPageviewProps = (props: Partial<PageviewProps>): PageviewProps => ({
  areaId: props.areaId,
  tenantIds: props.tenantIds,
  productId: props.productId,
  productIds: props.productIds,
  priceList: props.priceList,
  position: props.position,
})

type SearchParamKey = 'id' | 'product'

const getSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search)

  const params: Record<string, number> = {}
  for (const [key, value] of searchParams.entries()) {
    params[key] = Number(value)
  }
  return params as Record<SearchParamKey, number>
}

/**
 * 全ページ共通のfooterのボタンを押したとき
 * トップページの各Areaページへのリンク
 * トップページのTIMELIMITページへのリンク
 * QR MYCARD ページでの「QRコード」を押したとき
 * QR MYCARD ページで「商品一覧」を押したとき
 * QR MYCARD ページでの「商品をもう一度選ぶ」を押したとき
 *
 * チャット用
 * 全ページ共通のfooterのボタンを押したとき
 */
export const ELEMENT_PROPS_ELEMENTS = [
  'footer_home',
  'footer_wishlist',
  'footer_bag',
  'footer_brand',
  'guide_top_base_a',
  'guide_top_base_b',
  'guide_top_base_c',
  'guide_top_base_d',
  'guide_top_time_limit',
  'qr_mycard_qr_code',
  'qr_mycard_product_list',
  'qr_mycard_select_again',
  // chat
  'footer_chat',
  'footer_chat_scan',
  'footer_chat_bag',
] as const

/**
 * BASEページのブランドフィルターを押したとき
 */
export const AREA_ID_PROPS_ELEMENTS = ['base_brand_filter'] as const

/**
 * BASEページのブランドフィルターからブランドを選択したとき
 */
export const AREA_ID_TENANT_ID_PROPS_ELEMENTS = [
  'base_brand_filter_tenant',
] as const

/**
 * BASEページのブランドフィルターから検索を実行した時
 */
export const AREA_ID_SEARCH_PROPS_ELEMENTS = [
  'base_brand_filter_search',
] as const

/**
 * BASEページのブランドフィルターから絞り込みリセットを実行した時
 */
export const AREA_ID_FILTER_RESET_PROPS_ELEMENTS = [
  'base_brand_filter_reset',
] as const

/**
 * ブランドコンポーネントの「もっと読む」を押したとき
 */
export const TENANT_ID_PROPS_ELEMENTS = ['base_brand_more_load'] as const

/**
 * ブランドコンポーネントの「公式ページ」を押したとき
 */
export const TENANT_ID_URL_PROPS_ELEMENTS = [
  'base_brand_official_page',
] as const

/**
 * 商品ダイアログ内で「もっと詳細を見る」を押したとき
 * 商品ダイアログ内で「お気に入り」を押したとき
 */
export const PRODUCT_ID_ORDER_PROPS_ELEMENTS = [
  'product_read_more',
  'product_favorite',
] as const

/**
 * 商品ダイアログ内でカートに入れたとき
 */
export const PRODUCT_ID_ORDER_VARIANT_ID_PRICE_PROPS = [
  'product_entered_bag',
] as const

/**
 * 商品ダイアログ内で動画を再生したとき
 */
export const PRODUCT_ID_ORDER_MOVIE_PROPS = ['product_movie'] as const

/**
 * カートページで「お支払い手続きに進む」を押したとき
 */
export const PRODUCT_IDS_RECEIVE_LIST_PROPS_ELEMENTS = [
  'shopping_bag_payment',
] as const

/**
 * 連絡先登録ページで「決済QRコードを発行する」を押したとき
 */
export const PROVIDER_PROPS_ELEMENTS = ['dialog_auth_gen_qrcode'] as const

/**
 * カタログでQRコードを読み取り成功した時
 */
export const CATALOG_QRCODE_SCAN_SUCCESS_ELEMENTS = [
  'catalog_qrcode_scan_success',
] as const

/**
 * AIカタログでQRコードを読み取り成功した時
 */
export const CHAT_QRCODE_SCAN_SUCCESS_ELEMENTS = [
  'chat_qrcode_scan_success',
] as const

export function getClickProps(
  element: ClickElement,
  store: Store<any>,
  value?: Record<string, any>
) {
  switch (true) {
    case ELEMENT_PROPS_ELEMENTS.includes(element as any): {
      const props = createClickProps({
        element,
      })
      return props as ElementProps
    }

    case AREA_ID_PROPS_ELEMENTS.includes(element as any): {
      const { id: areaId } = getSearchParams()

      const props = createClickProps({
        element,
        areaId,
      })

      return props as AreaIdProps
    }

    case AREA_ID_TENANT_ID_PROPS_ELEMENTS.includes(element as any): {
      const { id: areaId } = getSearchParams()

      const props = createClickProps({
        element,
        areaId,
        tenantId: value?.tenantId,
        active: value?.active,
      })

      return props as AreaIdTenantIdProps
    }

    case AREA_ID_SEARCH_PROPS_ELEMENTS.includes(element as any): {
      const { id: areaId } = getSearchParams()

      const props = createClickProps({
        element,
        areaId,
        tenantIds: value?.tenantIds,
      })

      return props as AreaIdSearchProps
    }

    case AREA_ID_FILTER_RESET_PROPS_ELEMENTS.includes(element as any): {
      const { id: areaId } = getSearchParams()

      const props = createClickProps({
        element,
        areaId,
      })

      return props as AreaIdFilterResetProps
    }

    case TENANT_ID_PROPS_ELEMENTS.includes(element as any): {
      const props = createClickProps({
        element,
        tenantId: value?.tenantId,
        brandName: value?.brandName,
      })

      return props as TenantIdProps
    }

    case TENANT_ID_URL_PROPS_ELEMENTS.includes(element as any): {
      const props = createClickProps({
        element,
        tenantId: value?.tenantId,
        brandName: value?.brandName,
        brandUrl: value?.brandUrl,
      })

      return props as TenantIdUrlProps
    }

    case PRODUCT_ID_ORDER_PROPS_ELEMENTS.includes(element as any): {
      const { id: areaId, product: productId } = getSearchParams()

      const props = createClickProps({
        element,
        areaId,
        productId,
        position: store.getters['product/getPosition'](areaId, productId),
      })
      return props as ProductIdOrderProps
    }

    case PRODUCT_ID_ORDER_VARIANT_ID_PRICE_PROPS.includes(element as any): {
      const { id: areaId, product: productId } = getSearchParams()

      const props = createClickProps({
        element,
        areaId,
        productId,
        position: store.getters['product/getPosition'](areaId, productId),
        variantId: value?.variantId,
        price: value?.price,
      })
      return props as ProductIdOrderVariantIdProps
    }

    case PRODUCT_ID_ORDER_MOVIE_PROPS.includes(element as any): {
      const { id: areaId, product: productId } = getSearchParams()

      const props = createClickProps({
        element,
        areaId,
        productId,
        position: store.getters['product/getPosition'](areaId, productId),
        movieUrl: value?.movieUrl,
      })
      return props as ProductIdOrderMovieProps
    }

    case PRODUCT_IDS_RECEIVE_LIST_PROPS_ELEMENTS.includes(element as any): {
      const props = createClickProps({
        element,
        productIds: store.getters['cart/shopifyProductIds'],
        priceList: store.getters['cart/productPriceList'],
        receiveList: store.getters['cart/receiveList'],
      })
      return props as ProductIdsReceiveListProps
    }

    case PROVIDER_PROPS_ELEMENTS.includes(element as any): {
      const props = createClickProps({
        element,
        provider: value?.provider,
      })
      return props as ProviderProps
    }

    case CATALOG_QRCODE_SCAN_SUCCESS_ELEMENTS.includes(element as any): {
      const props = createClickProps({
        element,
        scanValue: value?.scanValue,
      })
      return props as CatalogQRCodeScanSuccess
    }

    case CHAT_QRCODE_SCAN_SUCCESS_ELEMENTS.includes(element as any): {
      const props = createClickProps({
        element,
        scanValue: value?.scanValue,
      })
      return props as ChatQRCodeScanSuccess
    }
  }
}

export function getPvProps(
  route: RouteLocationNormalizedLoaded | undefined,
  store: Store<any>
) {
  const currentPage = route?.query.product ? 'product' : route?.name

  switch (currentPage) {
    case 'area': {
      const { id: areaId } = getSearchParams()

      const tenantIds =
        store.getters['area/selectFilterOptionById'](areaId)?.tenantIds ?? []

      const props = createPageviewProps({ areaId, tenantIds })
      return props
    }

    case 'product': {
      const { id: areaId, product: productId } = getSearchParams()

      const props = createPageviewProps({
        areaId,
        productId,
        position: store.getters['product/getPosition'](areaId, productId),
      })
      return props
    }

    case 'cart': {
      const props = createPageviewProps({
        productIds: store.getters['cart/shopifyProductIds'],
        priceList: store.getters['cart/productPriceList'],
      })
      return props
    }

    /*
     * 注文完了
      case 'cart-orderId-complete':
        return {
          orderId: '123456789',
        }
     */
    default:
      return {}
  }
}
