import { GetterTree } from 'vuex'
import imageSrc from '@/assets/images/theme/theme5_the_shibuya_gift.webp'
import { Theme } from '~/models/Theme'
import { RootState } from '~/store'

export const state = (): { theme: Theme } => ({
  theme: {
    title: 'ザ・シブヤギフト',
    description: `誕生日やクリスマス、記念日など\nそれらも大切な日ではあるけれど\nそうじゃないすべての一日だって\n一度きりの大切な日には違いない\n\nもしもこの後に誰かと会うのなら\nとびっきりのいいことがあったら\nその想いをギフトにのせてみよう\n感情のままに何かを贈ってみよう\n\n無数のなんでもない日が集まれば\n世界はもっと色鮮やかに輝くはず\nさあ新しいギフトをこの場所から\n\nなんでもないひに\nザ・シブヤギフト\n`,
    imageSrc,
    fromDate: new Date('2024-07'),
  },
})

export type ThemeState = ReturnType<typeof state>

export const getters: GetterTree<ThemeState, RootState> = {
  theme: (state: ThemeState, getters: any, rootState: RootState): Theme => {
    return state.theme
  },
}
