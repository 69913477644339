import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  const router = useRouter()
  const store = useStore()

  store.commit('setMode', getModeFromUrl(window.location.href))

  router.afterEach(() => {
    store.commit('setMode', getModeFromUrl(window.location.href))
  })
})
