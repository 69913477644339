import { default as indexbiprgBvj4eMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/about/index.vue?macro=true";
import { default as completeGrFPTQA7XlMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/address/[shipmentAlias]/complete.vue?macro=true";
import { default as indexPS6KEeIyg1Meta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/address/[shipmentAlias]/index.vue?macro=true";
import { default as index72UFMpUI0CMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/area/index.vue?macro=true";
import { default as _91id_93OLOt3LsVM7Meta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/brands/[id].vue?macro=true";
import { default as indexsSRQGyGITRMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/brands/index.vue?macro=true";
import { default as indexo0y39vP2zKMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/cart/index.vue?macro=true";
import { default as indexFdAo8Cd49tMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/chat/index.vue?macro=true";
import { default as indext6UzV2QmlvMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/index.vue?macro=true";
import { default as _91orderAlias_93zNcvLk5reXMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/order/[orderAlias].vue?macro=true";
import { default as _91id_93JlSfqxzGGVMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/product-tags/[id].vue?macro=true";
import { default as indexBimPglNFi0Meta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/product-tags/index.vue?macro=true";
import { default as _91draftOrderAlias_93EsfWLHGjKLMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/qr/[draftOrderAlias].vue?macro=true";
import { default as timeoutzqtyoiJM71Meta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/qr/timeout.vue?macro=true";
import { default as index0GjoMCLfXGMeta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/terms/index.vue?macro=true";
import { default as indexUh4ONqSeZ8Meta } from "/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/wishlist/index.vue?macro=true";
import { default as indexAPeh24vigvMeta } from "~/pages/brands/index.vue?macro=true";
import { default as _91id_93W3rh2pvVmjMeta } from "~/pages/brands/[id].vue?macro=true";
import { default as indexqaf13ytSP0Meta } from "~/pages/cart/index.vue?macro=true";
import { default as indexgqYsaMc4pcMeta } from "~/pages/terms/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/about/index.vue")
  },
  {
    name: "address-shipmentAlias-complete",
    path: "/address/:shipmentAlias()/complete",
    meta: completeGrFPTQA7XlMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/address/[shipmentAlias]/complete.vue")
  },
  {
    name: "address-shipmentAlias",
    path: "/address/:shipmentAlias()",
    meta: indexPS6KEeIyg1Meta || {},
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/address/[shipmentAlias]/index.vue")
  },
  {
    name: "area",
    path: "/area",
    meta: index72UFMpUI0CMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/area/index.vue")
  },
  {
    name: "brands-id",
    path: "/brands/:id()",
    meta: _91id_93OLOt3LsVM7Meta || {},
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/brands/[id].vue")
  },
  {
    name: "brands",
    path: "/brands",
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/brands/index.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/cart/index.vue")
  },
  {
    name: "chat",
    path: "/chat",
    meta: indexFdAo8Cd49tMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/chat/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/index.vue")
  },
  {
    name: "order-orderAlias",
    path: "/order/:orderAlias()",
    meta: _91orderAlias_93zNcvLk5reXMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/order/[orderAlias].vue")
  },
  {
    name: "product-tags-id",
    path: "/product-tags/:id()",
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/product-tags/[id].vue")
  },
  {
    name: "product-tags",
    path: "/product-tags",
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/product-tags/index.vue")
  },
  {
    name: "qr-draftOrderAlias",
    path: "/qr/:draftOrderAlias()",
    meta: _91draftOrderAlias_93EsfWLHGjKLMeta || {},
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/qr/[draftOrderAlias].vue")
  },
  {
    name: "qr-timeout",
    path: "/qr/timeout",
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/qr/timeout.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/terms/index.vue")
  },
  {
    name: "wishlist",
    path: "/wishlist",
    component: () => import("/home/runner/work/ss-bonsai-catalog-frontend/ss-bonsai-catalog-frontend/app/pages/wishlist/index.vue")
  },
  {
    name: "ChatBrands",
    path: "/chat/brands",
    component: () => import("~/pages/brands/index.vue")
  },
  {
    name: "ChatBrandsDetail",
    path: "/chat/brands/:id",
    meta: _91id_93W3rh2pvVmjMeta || {},
    component: () => import("~/pages/brands/[id].vue")
  },
  {
    name: "ChatCart",
    path: "/chat/cart",
    component: () => import("~/pages/cart/index.vue")
  },
  {
    name: "ChatTerms",
    path: "/chat/terms",
    component: () => import("~/pages/terms/index.vue")
  }
]