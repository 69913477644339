<template>
  <div class="area">
    <v-window
      :model-value="windowIndex"
      continuous
      show-arrows
      :touch="false"
      @update:model-value="onWindowChange"
    >
      <template #prev="{ props }">
        <div class="slide-prev-button" @click="props.onClick">
          <img
            class="slide-button"
            src="~/assets/images/area/slide-btn-l.png"
            alt="←"
            width="50"
          />
        </div>
      </template>
      <template #next="{ props }">
        <div class="slide-next-button" @click="props.onClick">
          <img
            class="slide-button"
            src="~/assets/images/area/slide-btn-r.png"
            alt="→"
            width="50"
          />
        </div>
      </template>
      <v-window-item v-for="(area, index) in areas" :key="area.id">
        <AreaDetail
          :area="area"
          :loader-identifier="loaderIdentifier[index]"
          @filtering="() => onProductFiltering(index)"
        >
          <template v-if="area.place === areaD.place" #brands>
            <div class="area__brands">
              <p class="area__brand-title">[ 展示 ]</p>
              <AreaBrandCard
                class="area__brand-card"
                :logo-src="logoSrc"
                brand-url="https://tailoredcafe.jp/?utm_source=choosebase&utm_medium=catalog_shibuya&utm_campaign=base_d"
                brand-name="Tailoredcafe"
                brand-kana="テイラードカフェ"
                :tenant-id="0"
                description="完全キャッシュレスのパーソナライズドカフェです。自家焙煎のものを中心に、多彩なフレーバーのコーヒー豆を常時10種類以上揃えています。また専用モバイルオーダーアプリ「COFFEE App」を使い事前注文することで、待ち時間なくスムーズに商品の受取りが可能。月額定額プランも提供中。"
              />
            </div>
          </template>
        </AreaDetail>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { Area } from '~/models/Area'
import { ShopifyProductId } from '~/models/Product'
import logoSrc from '~/assets/images/area/brands/logo_tailoredcafe.jpg'

type DataType = {
  areaId: number
  selectShopifyProductId: ShopifyProductId
  loaderIdentifier: number[]
  logoSrc: string
}

type AsyncDataType = Pick<DataType, 'areaId'>
definePageMeta({
  layout: 'area',
})

export default defineNuxtComponent({
  async asyncData(): Promise<AsyncDataType | void> {
    const nuxtApp = useNuxtApp()
    const areaId = Number(useRoute().query.id)
    await nuxtApp.$store.dispatch('area/fetch')

    return {
      areaId,
    }
  },

  data(): DataType {
    return {
      areaId: 0,
      selectShopifyProductId: String(useRoute().query.product) ?? '',
      loaderIdentifier: [0, 1, 2, 3],
      logoSrc,
    }
  },

  computed: {
    ...mapGetters({
      areas: `area/areas`,
      areaA: `area/areaA`,
      areaB: `area/areaB`,
      areaC: `area/areaC`,
      areaD: `area/areaD`,
    }),
    windowIndex: {
      get(): number {
        return this.areas.map((it: Area) => it.id).indexOf(this.areaId) ?? 0
      },
      set(newVal: number) {
        this.areaId = this.areas[newVal].id
      },
    },
  },

  watch: {
    '$route.query.id'(newVal: string) {
      this.areaId = Number(newVal)
    },
  },

  methods: {
    onProductFiltering(index: number) {
      this.loaderIdentifier[index] = new Date().getTime()
    },
    async onWindowChange(newIndex: number) {
      this.windowIndex = newIndex
      await this.$router.push(`${this.$route.path}?id=${this.areaId}`)
      this.loaderIdentifier[newIndex] = new Date().getTime()
    },
  },
})
</script>

<style lang="sass" scoped>
.slide-prev-button,
.slide-next-button
  position: absolute
  top: 70px
  z-index: 2
  @media screen and (max-width: 376px)
    top: 60px
    @media screen and (max-width: 321px)
      top: 50px

  &:focus
    outline: none

.slide-prev-button
  left: 10px

.slide-next-button
  right: 10px

.area__brands
  padding: 0 28px

.area__brand-title
  font-size: 14px
  color: #666
  display: block
  text-align: center

.area__brand-card
  margin-bottom: 40px
</style>
