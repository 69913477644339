import { RuntimeConfig } from '@nuxt/schema'
import { defu } from 'defu'
import { FetchOptions } from 'ofetch'
import type { UseFetchOptions } from 'nuxt/app'

/**
 * -APIから返却されるエラー情報
 * HttpExceptionに付加されている
 * See: https://github.com/route06/ss-bonsai-api/blob/main/src/frameworks/exceptions/application-exception.filter.ts
 */
export interface BonsaiError {
  handledError: boolean
  message: string
  name: string
  statusCode: number
}

export function isBonsaiError(obj: any): obj is BonsaiError {
  return (
    obj &&
    typeof obj === 'object' &&
    'handledError' in obj &&
    typeof obj.handledError === 'boolean' &&
    'message' in obj &&
    typeof obj.message === 'string' &&
    'name' in obj &&
    typeof obj.name === 'string'
  )
}

export const BonsaiErrors = {
  DataNotFoundError: 'DataNotFoundError',
}

/**
 * CatalogApi へのリクエストかを判定する
 * @param request
 * @param config
 */
function checkRequestCatalogApi(
  request: RequestInfo,
  config: RuntimeConfig
): boolean {
  const requestUrl = request.url ?? ''
  const baseUrl = request.baseURL ?? ''

  if (/^http/.test(requestUrl)) {
    return config.public.apiBaseUrl.startsWith(requestUrl)
  } else {
    return config.public.apiBaseUrl.startsWith(baseUrl)
  }
}

/**
 * CatalogAPI 専用のカスタムヘッダーを付与する
 * @param request
 * @param config
 */
function setHeaderWithCatalogApi(options: FetchOptions, config: RuntimeConfig) {
  options.headers = {
    ...options.headers,
    'x-choosebase-with': config.public.apiCustomHeaderToken,
  }
}

/**
 * hostnameからsubdomainを除いた文字列を返す
 * @example
 * getCookieDomain('catalog.cbs06.com')
 * // '.cbs06.com'
 */
function getCookieDomain(hostname: string) {
  const hostnameArray = hostname.split('.')

  if (hostnameArray.length === 3) {
    hostnameArray.shift()
    return `.${hostnameArray.join('.')}`
  } else {
    return hostname
  }
}

// https://nuxt.com/docs/examples/advanced/use-custom-fetch-composable
export async function customFetch<T>(
  url: string,
  options: UseFetchOptions<T> = {}
) {
  const config = useRuntimeConfig()

  const defaults: UseFetchOptions<T> = {
    baseURL: config.public.apiBaseUrl,
    // cache request
    key: url,
    credentials: 'include',

    onRequest({ request, options }) {
      if (checkRequestCatalogApi(request, config)) {
        setHeaderWithCatalogApi(options, config)
      }
    },
  }

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults)
  return await useFetch(url, params)
}
