import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SelectFilterOption } from '~/models/FilterOption'
import { Product, ShopifyProductId } from '~/models/Product'
import { AreaState } from '~/store/area'
import { LanguageCode } from '~/stores/language'

const CACHE_TIME_MINUTE = 10
const PAGING_SIZE = 10
const productRepository = useProductRepository()

type StateType = {
  products: Record<ShopifyProductId, Product>
  language: LanguageCode | null
}
export const state = (): StateType => ({
  products: {},
  language: null,
})

export type ProductState = ReturnType<typeof state>

export const getters: GetterTree<ProductState, AreaState> = {
  productById: (
    state: ProductState,
    getters: any,
    areaState: AreaState
  ): ((shopifyProductId: ShopifyProductId) => Product | undefined) => {
    return (shopifyProductId: ShopifyProductId) => {
      return state.products[shopifyProductId]
    }
  },
  productsByAreaId: (
    state: ProductState,
    getters: any,
    areaState: AreaState,
    rootGetters: any
  ): ((areaId: number) => Product[]) => {
    return (areaId: number) => {
      return (
        rootGetters['area/productOrderById'](areaId) as ShopifyProductId[]
      ).map((it) => {
        return state.products[it]
      })
    }
  },
  getPosition:
    (
      state: ProductState,
      getters: any,
      areaState: AreaState,
      rootGetters: any
    ) =>
    (areaId: string, productId: number | string) => {
      const productIds = rootGetters['area/productOrderById'](
        areaId
      ) as string[]

      const position = productIds.indexOf(String(productId))
      return position === -1 ? undefined : position + 1
    },
}

export const mutations: MutationTree<ProductState> = {
  update(state: ProductState, payload: { products: Product[] }) {
    payload.products.forEach((it) => {
      state.products[it.shopifyProductId] = it
    })
    state.products = { ...state.products }
  },
  clear(state: ProductState, payload: { shopifyProductId: ShopifyProductId }) {
    delete state.products[payload.shopifyProductId]
  },
  clearProducts(state: ProductState) {
    state.products = {}
  },
  updateLanguage(
    state: ProductState,
    payload: { language: LanguageCode | null }
  ) {
    state.language = payload.language
  },
}

export const actions: ActionTree<ProductState, AreaState> = {
  async fetchProductsByAreaId(
    context: ActionContext<ProductState, AreaState>,
    { areaId, tenantIds }: { areaId: number; tenantIds: number[] }
  ) {
    // Area が無い場合は取得する
    {
      let area = context.rootGetters['area/areaById'](areaId)
      if (!area) {
        await context.dispatch('area/fetch', {}, { root: true })
        area = context.rootGetters['area/areaById'](areaId)
        if (!area) {
          throw new Error('invalid areaId')
        }
      }
    }

    clearProductsIfNeed(context, areaId, { tenantIds })

    // 全件取得済みの場合は return
    const productOrder = context.rootGetters['area/productOrderById'](areaId)
    const totalProduct =
      context.rootGetters['area/totalProductCountById'](areaId)
    if (productOrder.length >= totalProduct) {
      return
    }

    // 取得する page 値を計算する
    // FIXME ES の search_after を使うなどする？
    const page = Math.ceil(productOrder.length / PAGING_SIZE) + 1

    // 商品情報を取得
    const { products, count } = await productRepository.getProducts(
      areaId,
      page,
      PAGING_SIZE,
      tenantIds
    )

    // store 情報を更新
    context.commit(
      'area/addProductOrder',
      {
        areaId,
        totalProductCount: count,
        productOrder: products.map((it: Product) => it.shopifyProductId),
        tenantIds,
      },
      { root: true }
    )

    context.commit('update', { products })
  },

  async fetchProduct(
    context: ActionContext<ProductState, AreaState>,
    {
      shopifyProductId,
      language,
    }: {
      shopifyProductId: ShopifyProductId
      language: LanguageCode | null
    }
  ) {
    clearProductsIfLanguageChanged(context, language)

    const { product, error } = await productRepository.getProductById(
      shopifyProductId,
      language
    )

    if (error) {
      throw error
    }

    context.commit('update', { products: [product] })
    return {
      product,
    }
  },
}

const clearProductsIfLanguageChanged = (
  context: ActionContext<ProductState, AreaState>,
  language: LanguageCode | null
) => {
  if (context.state.language !== language) {
    context.commit('clearProducts')
    context.commit('updateLanguage', { language })
  }
}

const clearProductsIfNeed = (
  context: ActionContext<ProductState, AreaState>,
  areaId: number,
  newSelectFilterOption: SelectFilterOption
) => {
  // 絞り込み条件が変わっている場合は既存データの消去
  {
    const selectFilterOption = context.rootGetters[
      'area/selectFilterOptionById'
    ](areaId) ?? { tenantIds: [] }
    if (!equalFilterOption(selectFilterOption, newSelectFilterOption)) {
      context.commit('area/clearProductOrder', { areaId }, { root: true })
      return
    }
  }

  // 初回の取得時間が古い場合は既存データを消去
  {
    const firstFetchAt = context.rootGetters['area/firstFetchAtById'](areaId)
    if (firstFetchAt) {
      const diff = Date.now() - firstFetchAt.getTime()
      const isClearCache = diff / (60 * 1000) > CACHE_TIME_MINUTE
      if (isClearCache) {
        context.commit('area/clearProductOrder', { areaId }, { root: true })
      }
    }
  }
}

const equalFilterOption = (
  a: SelectFilterOption,
  b: SelectFilterOption
): boolean => {
  if (a.tenantIds.length !== b.tenantIds.length) {
    return false
  }
  return a.tenantIds.every((it) => b.tenantIds.includes(it))
}
