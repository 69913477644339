import { defineStore } from 'pinia' // TODO: テストのためにimportを追加。設定で解決したい
import { useNuxtApp } from '#app'

export const SUPPORTED_LANGUAGES = [
  { code: 'en', displayName: 'ENGLISH' },
  { code: 'zh', displayName: '中文' },
  { code: 'ko', displayName: '한국어' },
] as const

export type LanguageCode = (typeof SUPPORTED_LANGUAGES)[number]['code']
export type Language = (typeof SUPPORTED_LANGUAGES)[number]
export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES[0]

export const validateLanguageCode = (code: string): code is LanguageCode => {
  return SUPPORTED_LANGUAGES.map((lang) => lang.code as string).includes(code)
}
const validate = (code: string): LanguageCode => {
  return validateLanguageCode(code) ? code : DEFAULT_LANGUAGE.code
}

export const useLanguageStore = defineStore('language', {
  state: () => ({
    // 設定される値はSUPPORTED_LANGUAGESに含まれる値
    _languageCode: DEFAULT_LANGUAGE.code as LanguageCode,
    _isInitialized: false,
  }),
  getters: {
    languageCode: (state): LanguageCode => state._languageCode,
  },
  actions: {
    setLocale(code: string) {
      // i18nと重複する部分もあるが、設定されたlocale以外の値を扱おうとすると複雑になるので、
      // i18nはあくまで静的コンテンツにだけ使用することにして設定だけ行う
      const { $i18n } = useNuxtApp()
      $i18n.locale.value = code
    },
    setLanguageCode(code: string) {
      const validCode = validate(code)
      this._languageCode = validCode
      this.setLocale(validCode)
    },
    _initialize() {
      if (this._isInitialized) {
        return
      }
      this._isInitialized = true
      const userLangCode = navigator.language.split('-')[0] as LanguageCode
      const validCode = validate(userLangCode)
      this._languageCode = validCode
    },
    // 初回はブラウザから取得し、それ以外はStoreの値をロケールにセットする
    syncLocale() {
      this._initialize()
      this.setLocale(this._languageCode)
    },
  },
})
